@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tokens */
:root {
  --color-primary: 255 58 30; /* Text, buttons, etc. */
  --color-contrast: 250 250 249; /* Backgrounds, borders, etc. */
  --color-accent: 255 58 30; /* Labels like "On sale" */
  --font-size-fine: 0.75rem; /* text-xs */
  --font-size-copy: 1rem; /* text-base */
  --font-size-lead: 1.125rem; /* text-lg */
  --font-size-heading: 2rem; /* text-2xl */
  --font-size-display: 3rem; /* text-4xl */
  --color-shop-pay: #5a31f4;
  --shop-pay-button--width: 100%; /* Sets the width for the shop-pay-button web component */
  --height-nav: 3.875rem;
  --screen-height: 100vh;
  --screen-height-dynamic: 100vh;
  --font-family-brand: Jost;
  --height-banner: 3rem;
  --height-menu-footer: 7.75rem;

  @media (min-width: 64em) {
    --height-nav: 4.5rem;
    --height-banner: 2.125rem;
    --font-size-heading: 2.25rem; /* text-4xl */
    --font-size-display: 3.75rem; /* text-6xl */
  }
  @supports (height: 100svh) {
    --screen-height: 100svh;
  }
  @supports (height: 100dvh) {
    --screen-height-dynamic: 100dvh;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-primary: 255 58 30;
    --color-contrast: 250 250 249;
    --color-accent: 255 58 30;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

shop-pay-button {
  width: 100%;
  height: 3rem;
  display: table;
}

@font-face {
  font-family: "Jost";
  font-display: swap;
  font-weight: 400;
  src: url('./fonts/Jost-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Jost";
  font-display: swap;
  font-weight: bold;
  src: url('./fonts/Jost-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "Jost";
  font-display: swap;
  font-weight: 500;
  src: url('./fonts/Jost-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Jost";
  font-display: swap;
  font-weight: 600;
  src: url('./fonts/Jost-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Jost;
  font-display: swap;
  font-weight: 800;
  src: url('./fonts/Jost-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Jost;
  font-display: swap;
  font-weight: 900;
  src: url('./fonts/Jost-Black.ttf') format('truetype');
}

@layer base {
  * {
    font-variant-ligatures: none;
  }

  body {
    @apply border-primary-1/10 bg-white text-primary-1/90 antialiased;
  }

  html {
    scroll-padding-top: 10rem;
  }

  model-viewer::part(default-progress-mask) {
    display: none;
  }

  model-viewer::part(default-progress-bar) {
    display: none;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  a.active {
    @apply border-b;
  }

  .prose {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-child {
        @apply mt-0;
      }
    }
  }
}

@layer components {
  .article {
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply font-sans text-black text-2xl;
    }
    @apply prose mx-auto mb-12 grid justify-center;
    p,
    ul,
    li {
      @apply mb-4 text-base;
    }
    a {
      @apply text-primary-1;
    }
    img {
      @apply md:-mx-8 lg:-mx-16;

      @media (min-width: 48em) {
        width: calc(100% + 4rem);
        max-width: 100vw;
      }
      @media (min-width: 64em) {
        width: calc(100% + 8rem);
      }
    }
  }

  .swimlane {
    @apply grid w-full snap-x snap-mandatory scroll-px-6 grid-flow-col justify-start gap-4 overflow-x-scroll px-6 pb-4;
  }
}

@layer utilities {
  .fadeIn {
    opacity: 0;
    animation: fadeInAnimation ease 500ms forwards;
  }

  .hiddenScroll {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }

  .absolute-center {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .strike {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 108%;
      height: 1.5px;
      left: -4%;
      top: 50%;
      transform: translateY(-50%);
      background: rgb(var(--color-primary));
      box-shadow: 0.5px 0.5px 0px 0.5px rgb(var(--color-contrast));
    }
  }

  .card-image {
    @apply relative flex items-center justify-center overflow-clip rounded;
    &::before {
      content: ' ';
      @apply absolute top-0 left-0 z-10 block h-full w-full rounded shadow-border;
    }
    img {
      @apply aspect-[inherit] w-full object-cover;
    }
  }

  @screen md-max {
    .scrollMobile {
      @apply overflow-auto w-[100vw];
      > div {
        @apply flex;
        > div {
          min-width: 35%;
          &:last-child {
            min-width: 30px;
          }
        }
      }
    }
  }

  @screen sm-max {
    .scrollMobile {
      > div {
        @apply gap-4;
        > div {
          min-width: 64%;
        }
      }
    }
  }
}
